import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HeaderText = styled.div`
    padding: 1vw 1.5vw 1vw 12vw;
    font-size: 3vw;
    color: white;
    background: #242424;
    position: relative;
    display: flex;
    align-items: center;
    max-width: 38.2vw;
    height: 5vw;
    box-shadow: 0px 3px 6px #00000029;
`;

const BlackHeader = ({ children }) => <HeaderText>{children}</HeaderText>;
BlackHeader.propTypes = {
    children: PropTypes.node,
};

export default BlackHeader;
