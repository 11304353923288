import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RecommendationsContainer = styled.div`
    display: flex;
    position: relative;
    width: 45%;
    padding: 3vw 0px 3vw 3vw;
    background:  #efefef;
    flex-direction: column;
    margin-top: 3vw;
    align-items: center;
    min-width: 400px;

    @media (min-width: 768px) {
        min-width: 300px;
    }

    @media (max-width: 450px) {
        min-width: 90%;
    }
`;

const BlockNumber = styled.div`
    background: black;
    width: 9vw;
    height: 3vw;
    color: #a666cf;
    justify-self: flex-start;
    align-self: flex-start;
    font-size: 4vw;
    color: #a666cf;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0.5vw;
    margin-bottom: 4vw;
    box-shadow: 0px 2px 2px #00000033;

    @media (min-width: 900px) {
        font-size: 3vw;
    }
`;

const Description = styled.div`
    font-size: 2.5vw;
    align-self: flex-end;
    padding-right: 3vw;
    width: 100%;
    margin-bottom: 2vw;
    @media (min-width: 900px) {
        font-size: 1.2vw;
    }
`;

const PurpleSperator = styled.div`
    height: 1vw;
    width: 94%;
    background: #a666cf;
    position: absolute;
    bottom: 1vw;
    right: 3%;
`;

const RecommendationsBlock = ({ recNumber, description }) => (
    <RecommendationsContainer>
        <BlockNumber>{recNumber}#</BlockNumber>
        <Description>{description}</Description>
        <PurpleSperator />
    </RecommendationsContainer>
);

RecommendationsBlock.propTypes = {
    recNumber: PropTypes.number,
    description: PropTypes.string,
};

export default RecommendationsBlock;
