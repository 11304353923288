import React from 'react';
import styled from 'styled-components';
import RecommendationsBlock from './RecommendationsBlock';

const PurpleLine = styled.div`
    position: relative;
    z-index: -2;
    top: 4vw;
    width: 100%;
    height: 10px;
    background: #a666cf;
    box-shadow: 0px 3px 6px #00000029;
`;

const Header = styled.div`
    margin-right: 7vw !important;
    padding: 1vw 1.5vw 1vw 11vw;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 3vw;
    color: white;
    background: #242424;
    position: relative;
    display: flex;
    align-items: center;
    width: 35.8vw;
    height: 5vw;

    
    @media (min-width: 768px) {
        font-size: 2.5vw;
        width: 24.2vw;
    }
`;

const RecommendationsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
`;

const RecommendationsArray = [
    'ברגע שאחפש את יועצת המס, אני רוצה את אורית או אחת בדיוק כמו אורית, כי מעבר למקצועיות שהיא מפגינה המוך אכפתיות כלפי הלקוח ורואה בלקוח את האדם עם כל הקשיים והאתגרים ,מבינה אותנו וסבלנית לכל ההסברים בנושאים כל כך מסובכים לאנשים שלא מבינים את התחום" כך אמרה אחת המשתתפות בהרצאה של אורית',
    'עם סיומו של הקטע הראשון בדרכנו המשותפת תיאומי המס שהגיעו אתמול בדואר,כמובטח-אני רואה לעצמי חובה נעימה להודות לך ולהביע הערכה גדולה למקצועיות והנחישות שלך מהרגע הראשון ראית את התמונה הגדולה ,השגת עבורי תוצאות שלא חלמתי עליהן ,סללת עבורי את הכביש עליו אצעד בבטחה עוד שנים רבות  חשוב לציין כי פעלת בעיצומו של משבר הקורונה ולא התייאשת ולא הרמת ידיים אפילו לרגע אחד. תודה רבה לך מאחל לך הישגים מצוינים גם בעתיד ,עבורי ועבור לקוחות אחרים.',
    'את אורית זיצר הכרתי בסייברארק בשנת 2002 ומהר מאוד למדנו כל המנהלים שתאום מס סוף שנה עושים אצל אורית. מעבר לכך שאורית מקצועית בתחום ,השיגה לנו שקלים שלא  היינן רואים בעצמנו ,וגם היחס האישי יוצא הדופן ,תמיד עם חיוך ,תמיד סבלנית, גם שצריך להסביר לנו בפעם השלישית..  דוגמא מצוינת- מה שנחשבת ביעניי עבודת מקצוענים היא סיפור השנים 2015-2018 ,שנים של חוסר רצף ,מעבר בין מקומות , באלגן גדול!  -טפסי 106 חסרים ,משכורות פזורות ,חתימות בלשכה...לאורית זה לא שינה היא לקחה את ערימה הדפים הכבדה ,סייעה להשיג את החסר ,עשתה סדר באי סדר והגישה תיאום מס ל3 שנים של באלגן. קצה לקצה עם חיוך! לסיכום -מי שמבין שהחשוב זה מקצוענות מקצה לקצה ,ומעדיף שיטפל בו בן אדם מחויך ,מסור ,סבלני ומקצוען -אורית זיצר תהיה בחירה מצוינת .',
    'ממליצה בחום רב על אורית זיצר -קסם מהלך! מקצוענית ,אכפתית ,לוחמת עבור הלקוחות ומשיגה תוצאות אחד ויחידה ממליצה בחום ',
    'בחשבון הבנק שלי פתאום הופיע הבוקר סכום של 2836 שקלים ממס הכנסה! וואו! תודה רבה אורית את מדהימה! ',
    'לפני מספר שנים עם פרישתי לגמלאות, גיליתי להפתעתי כי אחד ממעסיקי בעבר, לאורך 20 שנות עבודה אשר הסתיימו לפני כ – 12 שנים, כשל בהעברת התשלומים המתחייבים בחוק לפנסיה ולפיצויים.    לאחר דין ודברים והגשת תביעה משפטית, הסתיים הנושא בפשרה כספית, אשר מן הסתם הייתה חייבת במיסוי הקבוע בחוק.  כאן נכנסו לעובי הקורה אורית זיצר ורונית אק אשר הצליחו לתפור עבורי חליפת דיווח ומיסוי מיטביים. ויותר מכך, אף הצליחו לשכנע את פקיד השומה לאשר אותה בשל הנסיבות המיוחדות. כתוצאה מכך, עשרות אלפי שקלים נוספו למסגרת הפטור לה אני זכאי ועשרות אלפי שקלים נחסכו בתשלומי המס.',
];

const Recommendations = React.forwardRef((props, ref) => (
    <>
        <PurpleLine ref={ref} />
        <Header>לקוחות ממליצים</Header>
        <RecommendationsContainer>
            {RecommendationsArray.map((recommendation, index) => (
                <RecommendationsBlock
                    key={recommendation}
                    description={recommendation}
                    recNumber={index + 1}
                />
            ))}
        </RecommendationsContainer>
    </>
));

Recommendations.displayName = 'Recommendations';

export default Recommendations;
