import React from 'react';
import styled from 'styled-components';
import ServiceBlock from './ServiceBloack';

const PurpleLine = styled.div`
    position: relative;
    z-index: -2;
    top: 3vw;
    width: 100%;
    height: 10px;
    background: #a666cf;
`;

const Header = styled.div`
    margin-right: 7vw !important;
    padding: 1vw 1.5vw 1vw 12vw;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 3vw;
    color: white;
    background: #242424;
    position: relative;
    display: flex;
    align-items: center;
    width: 34.5vw;
    height: 4vw;

    @media (min-width: 768px) {
        font-size: 2.5vw;
        width: 31.2vw;
    }
`;

const Services = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 3vw;
`;

const services = [
    {
        header: 'הקמת עסק',
        description:
            'כדי להפסיק להתלבט ולקבל הרבה ידע מעשי ופתיחת העסק הלכה למעשה, כדאי להגיע אלי ולהפסיק לחשוש- מקסימום תצליחו',
        imgSrc: require('../assets/business-opening.png').default,
    },
    {
        header: 'יעוץ עסקי',
        description:
            ' חלק מהשרות שלי הוא מתן טיפים להתנהלות עסקית נכונה, לדוגמה: ניהול תזרים מזומנים מה שחשוב לדעת הוא איך בעל העסק מנהל את הכסף ולא להפך',
        imgSrc: require('../assets/financial-advise.png').default,
    },

    {
        header: 'יעוץ מס עבור עצמאים ושכירים',
        description:
            'כיצד לשלם מינימום מיסים על ידי הכרה בכל נקודות הזיכוי המגיעים לכם, תשלום מקדמות מס הכנסה וביטוח לאומי והערכות לתקופות קשות',
        imgSrc: require('../assets/tax-advise.png').default,
    },
    {
        header: 'הנהלת חשבונות',
        description: 'קליטת דפי בנק, התאמות שוטפות דו״ח רווח והפסד ומאזן',
        imgSrc: require('../assets/bookkeeping.png').default,
    },
    {
        header: 'משכורות',
        description:
            'הכנת תלושי שכר לעובדים, תשלומים לביטוח לאומי ומס הכנסה, דו״ח 126 לביטוח לאומי ומס הכנסה',
        imgSrc: require('../assets/patchecks.png').default,
    },
    {
        header: 'דוחות שנתיים',
        description:
            'הכנת דו״חות מס לעצמאים, תוך חסכון מקסימלי במיסים מנסיון רב שנים',
        imgSrc: require('../assets/year-report.png').default,
    },
    {
        header: 'מיסוי פרישה',
        description:
            'כל מי שפרש ומשלם מס הכנסה בתלוש הפנסיה, מבצעת עבורו ״קיבוע זכויות״ דבר שחוסך המון כסף במיסים עד סוף החיים',
        imgSrc: require('../assets/pensia-tax.png').default,
    },
    {
        header: 'מיסוי אמריקאי',
        description:
            'לקוחות בעלי אזרחות אמריקאית מקבלים שרות מלא שכולל דו״ח מס אמריקאי( על ידי רואה חשבון שמתמחה בכך) ובארץ תאום מלא למניעת כפל מס',
        imgSrc: require('../assets/american-tax.png').default,
    },
];

const OurServices = () => (
    <>
        <PurpleLine />
        <Header>השירותים שלנו</Header>
        <Services>
            {services.map((service) => (
                <ServiceBlock key={service.header} {...service} />
            ))}
        </Services>
    </>
);

export default OurServices;
