import React from 'react';
import styled from 'styled-components';

const PurpleLine = styled.div`
    width: 100%;
    height: 5px;
    background: #a666cf;
`;

const BlackLine = styled.div`
    background: black;
    height:  56px;
    width: 100%;
`;

const Footer = () => (
    <>
        <PurpleLine />
        <BlackLine/>
    </>
);

export default Footer;
