import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    position: fixed;
    top: 0;
    z-index: 333;
    width: 100%;
    height: 7vw;
    background: #f5f3f2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 3px 6px #00000029;

    @media (min-width: 768px) {
        padding-right: 74px;
        height: 4vw;
        justify-content: flex-start;
    }
`;

const MenuItem = styled.div`
    color: #a666cf;
    font-size: 3vw;
    font-weight: 800;
    z-index: 33;
    &:hover {
        color: black;
        cursor: pointer;
    }

    @media (min-width: 768px) {
        margin-right: 4vw;
        font-size: 1.4vw;
    }
`;

const HeaderItems = [
    {
        value: 'Home',
        text: 'דף הבית',
    },
    {
        value: 'About',
        text: 'אודות',
    },
    {
        value: 'Recommendations',
        text: 'המלצות',
    },
    {
        value: 'Contact',
        text: 'צור קשר',
    },
];

const HeaderMenu = ({ scrollToContent }) => (
    <Container>
        {HeaderItems.map((item) => (
            <MenuItem
                key={item.text}
                onClick={() => scrollToContent(item.value)}
            >
                {item.text}
            </MenuItem>
        ))}
    </Container>
);

HeaderMenu.propTypes = {
    scrollToContent: PropTypes.Function,
};

export default HeaderMenu;
