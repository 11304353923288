import React from 'react';
import styled from 'styled-components';
import image from '../assets/calculator.png';

const Container = styled.div`
    width: 100%;
    margin-top: 7vw;
    height: 35vw;
    display: flex;
    justify-content: space-between;
    @media (min-width: 768px) {
        margin-top: 4vw;
        height: 30vw;
    }
`;

const Image = styled.div`
    height: 100%;
    width: 100%;
    top: 0;
    background-position: -15vw;
    background-repeat: no-repeat;

    @media (min-width: 768px) {
        background-position: left center;
    }
`;

const TextContainer = styled.div`
    display: flex;
    max-width: 30vw;
    flex: 1;
    align-self: center;
    top: 0;
    padding-right: 4%;
    z-index: 2;
`;

const Text = styled.div`
    font-size: 2vw;
    color: black;
    margin-top: 1vw;
    font-weight: 200;
    width: 46vw;
`;

const BigText = styled.div`
    font-size: 4vw;
    font-weight: 900;
    color: black;
`;

const Sperator = styled.div`
    background: white;
    width: 1.5vw;
`;

const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageContainer = styled.div`
    display: flex;
    flex: 2;
`;

const WhiteTriangleBackground = styled.div`
    background: white;
    z-index: 0;
    height: 31vw;
    margin-right: -4.5vw;
    width: 40vw;
    transform: rotate(90deg);
    position: absolute;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
`;

const HeaderCover = () => (
    <Container>
        <TextContainer>
            <Sperator />
            <QuestionContainer>
                <BigText>
                    משרד לייעוץ מס <br />
                    והנהלת חשבונות.
                </BigText>
                <Text>מחפשים מישהי שמדברת את השפה?</Text>
            </QuestionContainer>
        </TextContainer>
        <ImageContainer>
            <WhiteTriangleBackground />
            <Image
                style={{ backgroundImage: `url(${image})` }}
                alt="Orit Zitzer cover picture אורית זיצר יועצת מס קאבר"
            />
        </ImageContainer>
    </Container>
);

export default HeaderCover;
