import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import Main from './components/Main';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="*" element={<Main />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
