import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Loader from 'react-loader-spinner';

const PurpleLine = styled.div`
    position: relative;
    z-index: 0;
    top: 2vw;
    width: 100%;
    height: 10px;
    background: #a666cf;
`;

const Header = styled.div`
    margin-right: 7vw !important;
    padding: 1vw 2.5vw 1vw 11vw;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 3vw;
    color: white;
    background: #242424;
    position: relative;
    display: flex;
    align-items: center;
    width: 27.2vw;
    height: 4vw;
    z-index: 2;

    @media (min-width: 768px) {
        font-size: 2.5vw;
        width: 24.2vw;
    }
`;

const ContactContainer = styled.div`
    display: flex;
    background: #efefef;
    width: 100%;
    margin-top: -2vw;
    padding-top: 4vw;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
        padding-right: 0;
        justify-content: space-evenly;
    }
`;

const ContactDetail = styled.div`
    display: flex;
    margin-top: 2vw;

    @media (min-width: 768px) {
        margin-top: 8vw;
    }
`;

const ContactDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5vw;
    align-items: center;
    @media (min-width: 768px) {
        align-items: flex-start;
        margin-bottom: 1vw;
    }
`;

const ContactIcon = styled.img`
    height: 100%;
`;

const ContactIconContainer = styled.div`
    width: 2vw;
    height: 2.5vw;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
        height: 1.5vw;
    }
`;

const ContactDesc = styled.div`
    font-size: 3vw;
    margin-right: 10px;
    color: #707070;

    @media (min-width: 768px) {
        font-size: 1.5vw;
    }
`;

const FormHeader = styled.div`
    padding: 1vw 1vw 1vw 3vw;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 2.5vw;
    color: white;
    background: #a666cf;
    width: 25vw;

    @media (min-width: 768px) {
        font-size: 1.8vw
    }
`;

const Input = styled.input`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.75;
    margin-top: 2vw;
    border: none;
    height: 6vw;
    width: 60%;
    font-family: 'Rubik';
    line-height: none !important;
    padding-right: 1vw;
    margin-left: 0.3vw;
    font-size: 2.5vw;

    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    &::placeholder {
        font-family: 'Rubik';
        color: black;
        font-weight: bold;
    }

    @media (min-width: 768px) {
        flex-direction: row;
        width: 45%;
        height: 4vw;
        font-size: 1.2vw;
    }
`;

const TextArea = styled.textarea`
    width: 60%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.75;
    margin-top: 2vw;
    border: none;
    height: 15vw;
    resize: none;
    font-family: 'Rubik';
    padding: 1vw 1vw 0 0;
    font-size: 2.5vw;

    &::placeholder {
        color: black;
        font-weight: bold;
        font-family: 'Rubik';
    }

    @media (min-width: 768px) {
        width: 50vw;
        font-size: 1.2vw;
    }
`;

const InputsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const SendLoader = styled(Loader)`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin-top: 2vw;
`;

const SendButton = styled.div`
    width: 12vw;
    height: 5vw;
    border-radius: 1vw;
    background: #a666cf;
    font-size: 3vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin-top: 2vw;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    @media (min-width: 768px) {
        width: 8vw;
        height: 3vw;
        font-size: 1.5vw;
    }
`;

const Text = styled.div`
    margin-top: 2vw;
    font-size: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContactDetails = [
    {
        icon: require('../assets/whatsapp-contact.png').default,
        text: <a href="https://api.whatsapp.com/send?phone=972525557541">052-555-7541</a>,
    },
    {
        icon: require('../assets/phone.png').default,
        text: <a href="tel:052-555-7541">052-555-7541</a>,
    },
];

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Contact = React.forwardRef((props, ref) => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [sendStatus, setSendStatus] = React.useState('');

    const onSendClicked = () => {
        if (!name | !email | !message || !phone) {
            setSendStatus('אנא מלא את כל השדות');
            return;
        }

        if (!EMAIL_REGEX.test(String(email).toLowerCase())) {
            setSendStatus('אנא מלא מייל תקין');
            return;
        }

        setSendStatus('');
        setIsLoading(true);
        axios({
            method: 'post',
            url: 'https://orit-mail.herokuapp.com/',
            headers: { 'content-type': 'application/json' },
            data: {
                name,
                message,
                email,
                phone
            },
        })
            .then(() => {
                setSendStatus('ההודעה נשלחה בהצלחה');
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setIsLoading(false);
                window.history.pushState('thankYou', 'תודה נחזור אליך בהקדם!', '/thank-you');
            })
            .catch((error) => {
                setSendStatus('שגיאה בשליחת ההודעה');
                console.error(error);
                setIsLoading(false);
            });
    };

    return (
        <Container ref={ref}>
            <PurpleLine />
            <Header> צור קשר</Header>
            <ContactContainer>
                <ContactDetailContainer>
                    {ContactDetails.map(({ icon, text }) => (
                        <ContactDetail key={text}>
                            <ContactIconContainer>
                                <ContactIcon src={icon}></ContactIcon>
                            </ContactIconContainer>
                            <ContactDesc>{text}</ContactDesc>
                        </ContactDetail>
                    ))}
                </ContactDetailContainer>

                <ContactDetailContainer>
                    <FormHeader>בא לכם להתייעץ?</FormHeader>
                    <InputsContainer>
                        <Input
                            type="text"
                            placeholder="שם מלא *"
                            disabled={false}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="אימייל *"
                            disabled={false}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Input
                            type="number"
                            placeholder="טלפון *"
                            disabled={false}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </InputsContainer>
                    <TextArea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="הקלד כאן"
                    ></TextArea>
                    {!isLoading ? (
                        <SendButton onClick={onSendClicked}>שלח</SendButton>
                    ) : (
                        <SendLoader
                            type="Puff"
                            color="#a666cf"
                            height="4vw"
                            width="4vw"
                        />
                    )}
                    <Text>{sendStatus}</Text>
                </ContactDetailContainer>
            </ContactContainer>
        </Container>
    );
});

Contact.displayName = 'Contact';
export default Contact;
