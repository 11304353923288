import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Header = styled.div`
    width: 80%;
    height: 7vw;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 2.5vw;
    color: white;
    background: #242424;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2vw;
    @media (min-width: 768px) {
        font-size: 1.5vw;
        height: 4vw;
    }
`;

const PurpleBackground = styled.img`
    height: 100%;
    width: 100%;
    position: absolute;
    display: initial;
    @media (min-width: 768px) {
        display: none;
    }
`;

const DescriptionContainer = styled.div`
    display: initial;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    display: flex;

    @media (min-width: 768px) {
        display: none;
        top: 47%;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 6vw 1vw 0 1vw;
    position: relative;

    &:hover ${PurpleBackground} {
        display: initial;
    }

    &:hover ${DescriptionContainer} {
        display: flex;
    }
`;

const BackgroundImage = styled.img`
    align-self: flex-start;
    width: 40vw;
    height: auto;
    position: relative;
    top: 0;
    left: 0;

    @media (min-width: 768px) {
        width: 310px;
    }

    @media (max-width: 333px) {
        width: 90%;
    }
`;

const Description = styled.div`
    padding: 0 2vw;
    font-size: 2.5vw;
    z-index: 22;
    text-align: center;
    @media (min-width: 768px) {
        font-size: 1.3vw;
    }
`;

const BlackLine = styled.div`
    height: 9px;
    background: #242424;
    margin-bottom: 2vw;
    z-index: 22;
    width: 90%;
`;

const ServiceBlock = ({ imgSrc, header, description }) => {
    return (
        <Container>
            <BackgroundImage
                src={imgSrc}
                alt={`Orit Zitzer service ${header}`}
            />
            <Header>{header}</Header>
            <DescriptionContainer>
                <BlackLine />
                <Description>{description}</Description>
            </DescriptionContainer>
            <PurpleBackground
                src={require('../assets/puprle-cover.png').default}
            />
        </Container>
    );
};

ServiceBlock.propTypes = {
    imgSrc: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
};

export default ServiceBlock;
