import React from 'react';
import styled from 'styled-components';

const Sperator = styled.div`
    width: 100%;
    height: 10px;
    background: #A666CF;
`;

const PurpleSperator = () => (
    <Sperator/>
);

export default PurpleSperator;
