import React from 'react';
import About from './About';
import HeaderCover from './HeaderCover';
import HeaderMenu from './HeaderMenu';
import OurServices from './OurServices';
import PurpleSperator from './PurpleSeperator';
import Recommendations from './Recommendations';
import Contact from './Contact';
import Footer from './Footer';

function Main() {
    const aboutRef = React.useRef();
    const recommendationsRef = React.useRef();
    const contactRef = React.useRef();

    const scrollToContent = React.useCallback(
        (content) => {
            switch (content) {
                case 'About':
                    aboutRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                    break;
                case 'Recommendations':
                    recommendationsRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                    break;
                case 'Contact':
                    contactRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                    break;
                case 'Home':
                    window.scrollTo(0, 0);
            }
        },
        [aboutRef, recommendationsRef, contactRef],
    );

    return (
        <div className="App">
            <HeaderMenu scrollToContent={scrollToContent} />
            <HeaderCover />
            <Contact ref={contactRef} />
            <PurpleSperator />
            <About ref={aboutRef} />
            <OurServices />
            <Recommendations ref={recommendationsRef} />
            <Footer />
        </div>
    );
}

export default Main;
