import React from 'react';
import styled from 'styled-components'; 
import BlackHeader from './BlackHeader';

const Container = styled.div`
    display: flex;
    padding-right: 2vw;
    margin-top: 4vw;
    margin-bottom: 3vw;
`;

const Image = styled.img`
    max-width: 25vw;
    object-fit: cover;
    shadow: 0px 3px 6px #00000029;
`;

const RightContainer = styled.div`
    display: flex;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 9vw;
    padding-top: 3vw;
`;

const Orit = styled.div`
    position: absolute;
    background: #a666cf;
    top: 4vw;
    left: -3.5vw;
    color: white;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
    padding: 1vw 3vw 1vw 3vw;
    font-size: 3vw;
    height: 4vw;

    @media (min-width: 768px) {
        font-size: 2.3vw;
    }
`;

const Desc = styled.div`
    font-size: 2.5vw;
    margin-top: 5vw;
    margin-left: 5vw;
    @media (min-width: 768px) {
        font-size: 1.3vw;
    }
`;

const Links = styled.div`
    display: flex;
    margin-left: 5vw;
    justify-content: center;
    margin-top: 3vw;
`;

const LmageLink = styled.img`
    height: 4.5vw;
    margin-left: 1vw;
    &:hover {
        cursor: pointer;
    }
`;

const About = React.forwardRef((props, ref) => (
    <Container ref={ref}>
        <RightContainer>
            <Image
                src={require('../assets/about-new.jpg').default}
                alt="Orit Zitzer profile about picture אורית זיצר יועצת מס פרופיל"
            />
        </RightContainer>
        <LeftContainer>
            <BlackHeader>
                <span>היי נעים להכיר</span>
                <Orit>אורית זיצר</Orit>
            </BlackHeader>
            <Desc>
                אורית זיצר יועצת מס מוסמכת בעלת ניסיון של למעלה ל-20 שנים בחברות
                הייטק ובמשרדי רואי חשבון, מנהלת משרד המתמחה ביעוץ מס לעצמאיים
                ושכירים ,הדרכה וליווי בפתיחת עסק חדש, הנהלת חשבונות ,משכורות
                ,מיסוי אמריקאי ,דוח שנתי ,יעוץ עסקי ,עזרה במיסוי פרישה ועוד. אני
                פה כדי לעזור לכם, לחסוך עבורכם ולראות אותכם גדלים
            </Desc>
            <Links>
                {[
                    {
                        value: 'facebook',
                        link: 'https://www.facebook.com/oritzit',
                    },
                    {
                        value: 'linkedin',
                        link: 'https://il.linkedin.com/in/orit-zitser',
                    },
                    {
                        value: 'whatsapp',
                        link:
                            'https://api.whatsapp.com/send?phone=972525557541',
                    },
                ].map(({ value, link }) => (
                    <a key={value} href={link}>
                        <LmageLink
                            src={require(`../assets/${value}.png`).default}
                        />
                    </a>
                ))}
            </Links>
        </LeftContainer>
    </Container>
));

About.displayName = 'About';

export default About;
